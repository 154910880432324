import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";

import {
  ICandidateEvaluationHistory,
  ICandidateOpportunitiesEvaluationSummary,
  MemberEvaluationComment
} from "./DTOs/CandidateEvaluationSummary";
import { ForwardedService, ICreatedID } from "./DTOs/Misc";
import { IOpportunity } from "./DTOs/Opportunity";
import { IPaginatedDTO } from "./DTOs/PaginatedDTO";
import {
  IAssignAccountManagerResponseDTO,
  IBioFindQuery,
  IJsGgId,
  IOperator,
  IPerson,
  IPersonBio,
  ISimplePerson,
  IUpdateOperatorDTO,
  OperatorStatus,
  Person
} from "./DTOs/Person";
import { IPersonalId, IPersonalIdCountry } from "./DTOs/PersonalIds";
import { ICCGCreationResponse, ICCGCreationWithLinkedinData } from "./DTOs/RecruiterChatbot";
import { IService } from "./DTOs/TorreServices";
import HTTPService from "./HTTPService";

class Service extends HTTPService {
  public getPersonInfoFromBio(query: IBioFindQuery): Observable<Person> {
    return this.forwardPost$<{ person: IPerson }>(
      ForwardedService.BIO,
      "/admin/bios/find",
      query
    ).pipe(map(data => new Person(data.person)));
  }

  public getPeopleBioByGgIds(ggIds: string[]): Observable<ISimplePerson[]> {
    if (ggIds.length === 0) {
      return of([]);
    }
    return this.get$<ISimplePerson[]>(`/api/people/${ggIds.join(",")}/bio`);
  }

  public getPersonBio(publicId: string): Observable<IPersonBio> {
    return this.get$<IPersonBio>(`/api/external-bios/${publicId}`, { publicId });
  }

  public updateExternalPersonFlags(publicId: string, flagName: string, state: boolean): Observable<void> {
    return this.put$(`/api/external-bios/${publicId}/flags/${flagName}`, { state });
  }

  public externalImportResume(publicId: string, file: File | null): Observable<string> {
    const formData = new FormData();

    if (file) {
      formData.append("file", file);
    }

    return this
      .instantiateCustomHeaders({ "Content-Type": "multipart/form-data" })
      .post$<string>(`/api/external-bios/${publicId}/import-resume`, formData);
  }

  public deleteExternalUndoImportedResume(publicId: string, importGroup: string): Observable<any> {
    return this.delete$(`/api/external-bios/${publicId}/import-resume/${importGroup}`);
  }

  public setPersonOpportunityOperatorFlagAsTrue(
    operatorGgId: string
  ): Observable<string[]> {
    return this.forwardPut$<string[]>(
      ForwardedService.DISCOVERY,
      `/api-admin/users/${operatorGgId}/opportunity-operator`,
      {}
    );
  }

  public updatePersonAccessCohortFlag(data: {
    personGgId: string
    value: boolean
  }): Observable<void> {
    return this.forwardPut$(
      ForwardedService.BIO,
      `/admin/people/${data.personGgId}/flags`,
      { accessCohort: data.value }
    );
  }

  public findUserByGgId(data: { targetGgId: string, hasEmail: boolean }): Observable<ISimplePerson | null> {
    return this.forwardGet$<ISimplePerson[]>(
      ForwardedService.BIO,
      `/io/people/${data.targetGgId}/bios?hasEmail=${data.hasEmail}`,
      { }
    ).pipe(
      map((response: ISimplePerson[]) => response.length > 0 ? response[0] : null)
    );
  }

  public updateCcgEmail(data: {
    publicId: string
    newEmail: string
  }): Observable<void> {
    return this.forwardPut$(
      ForwardedService.BIO,
      `/admin/people/${data.publicId}/populate`,
      {
        email: data.newEmail,
        socialNetworks: [],
        strengths: [],
        languages: [],
        experiences: [],
        preferences: {}
      }
    );
  }

  public assignAccountManagerToTalentSeeker(
    tsGgIds: string[],
    amGgId: string
  ): Observable<IAssignAccountManagerResponseDTO> {
    return this.forwardPost$<IAssignAccountManagerResponseDTO>(
      ForwardedService.DISCOVERY,
      `/api-admin/users/${tsGgIds.join()}/account-manager/${amGgId}`,
      {}
    );
  }

  public updateOnboardedStatus(ggId: string, status: string): Observable<number[]> {
    return this.put$<number[]>(`/api/talent-seeker/${ggId}/onboarding-status`, { status });
  }

  public getPersonGenomeToken(ggId: string): Observable<string> {
    return this.forwardPost$<{ token: string }>(
      ForwardedService.DISCOVERY,
      "/io/suite/tokens",
      { ggId, url: "/postings/list/posted" }
    ).pipe(map(data => data.token));
  }

  public getCandidateEvaluationSummaryByCandidatePersonGgId(
    ggId: string,
    page: number,
    candidateInformationFiltered: boolean
  ): Observable<IPaginatedDTO<ICandidateOpportunitiesEvaluationSummary>> {
    return this.forwardGet$<IPaginatedDTO<ICandidateOpportunitiesEvaluationSummary>>(
      ForwardedService.DISCOVERY,
      `/api-admin/opportunities/all/candidates/${ggId}/summaries`,
      { page, pageSize: 10, candidateInformationFiltered }
    );
  }

  public getCandateEvaluationHistoryByCandidatePersonGgId(
    data: IJsGgId
  ): Observable<ICandidateEvaluationHistory> {
    return this.forwardGet$<ICandidateEvaluationHistory>(
      ForwardedService.DISCOVERY,
      `/api-admin/opportunities/all/candidates/${data.jsGgid}/evaluation-history`
    );
  }

  public getCandateEvaluationCommentsByCandidatePersonGgId(
    ggId: string
  ): Observable<MemberEvaluationComment[]> {
    return this.forwardGet$<MemberEvaluationComment[]>(
      ForwardedService.HERMES,
      `/io/interactions/${ggId}/notes`
    );
  }

  public getOperatorFlags(operatorGgId: string): Observable<IOperator> {
    return this.get$<IOperator>(`/api/operators/${operatorGgId}`);
  }

  public getOperators(status: OperatorStatus = OperatorStatus.ACTIVE, fastFail?: Boolean): Observable<IOperator[]> {
    return this.get$<IOperator[]>("/api/operators", { status, fastFail });
  }

  public getAllCandidateRecruiters(): Observable<ISimplePerson[]> {
    return this.get$<ISimplePerson[]>("/api/operators/all/candidate-recruiters");
  }

  public getPersonServices(publicId: string): Observable<{services: IService[]}> {
    return this.get$<{services: IService[]}>(`/api/people/${publicId}/services`);
  }

  public getPersonAccountManager(publicId: string): Observable<IPerson> {
    return this.get$<IPerson>(`/api/people/${publicId}/account-manager`);
  }

  public deletePersonAccountManager(publicId: string): Observable<void> {
    return this.delete$(`/api/people/${publicId}/account-manager`);
  }

  public getPersonOpportunities(ggId: string, page: number = 0, pageSize: number = 20): Observable<IPaginatedDTO<IOpportunity>> {
    return this.get$<IPaginatedDTO<IOpportunity>>(`/api/people/${ggId}/opportunities`, { page, pageSize });
  }

  public addOrUpdateOperator(operatorGgId: string, data: IUpdateOperatorDTO): Observable<ICreatedID> {
    return this.put$<ICreatedID>(`/api/operators/${operatorGgId}`, data);
  }

  public updateEarlyAccessFlag(ggId: string, flag: string): Observable<void> {
    return this.forwardPut$<void>(
      ForwardedService.DISCOVERY,
      `/io/users/${ggId}/invite/status/${flag}`,
      {}
    );
  }

  public getValidPersonalIdCountries(): Observable<IPersonalIdCountry[]> {
    return this.forwardGet$<IPersonalIdCountry[]>(
      ForwardedService.BIO,
      "/io/valid-countries-available"
    );
  }

  public getPersonalIds(publicId: string): Observable<{ ids: IPersonalId[] }> {
    return this.forwardGet$<{ ids: IPersonalId[] }>(
      ForwardedService.BIO,
      `/admin/people/${publicId}/personal-ids`
    );
  }

  public setPersonalIds(publicId: string, data: IPersonalId[]): Observable<void> {
    return this.forwardPost$<void>(
      ForwardedService.BIO,
      `/admin/people/${publicId}/personal-ids`,
      { ids: data }
    );
  }

  public importCCGToCreator(creatorPersonGgId: string, data: ICCGCreationWithLinkedinData): Observable<ICCGCreationResponse> {
    return this.forwardPost$<ICCGCreationResponse>(
      ForwardedService.BIO,
      `/io/people/${creatorPersonGgId}/add-bio`,
      { ...data, creatorSource: "import" }
    );
  }

  public importCCGFromResumeToCreator(creatorPersonGgId: string, file: File): Observable<ICCGCreationResponse> {
    const formData = new FormData();

    formData.append("file", file);

    return this.forwardPost$<ICCGCreationResponse>(
      ForwardedService.BIO,
      `/io/people/${creatorPersonGgId}/parse-bio`,
      formData
    );
  }
}

export const PersonService = new Service();
