import { map, Observable } from "rxjs";

import { ForwardedService } from "@/services/DTOs/Misc";
import { IService } from "@/services/DTOs/TorreServices";
import HTTPService from "@/services/HTTPService";

class Service extends HTTPService {
  public get(): Observable<IService[]> {
    return this.forwardGet$<{ services: IService[] }>(ForwardedService.DISCOVERY, "/services-definition")
      .pipe(map((response) => {
        return response.services;
      }));
  }
}

export const ServicesDefinitionService = new Service();
