import { map, Observable } from "rxjs";

import { ICreatedID, IUpdatedRecords } from "./DTOs/Misc";
import {
  IOperatorsPermissionsDTO,
  IPermissionDTO,
  IPermissionGroupDTO,
  ISavePermissionDTO,
  ISavePermissionGroupDTO,
  IUpdateOperatorsPermissionDTO,
  IUpdatePermissionDTO,
  PermissionTinyDTO
} from "./DTOs/Permission";
import HTTPService from "./HTTPService";
import { MapToStringKeyedObject } from "@/helpers/ObjectHelpers";

class Service extends HTTPService {
  public getAllPermissions(): Observable<IPermissionDTO[]> {
    return this.get$<IPermissionDTO[]>("/api/auth/permissions");
  }

  public createPermission(permission: ISavePermissionDTO): Observable<ICreatedID> {
    return this.post$<ICreatedID>("/api/auth/permission", permission);
  }

  public updatePermission(id: number, permission: IUpdatePermissionDTO): Observable<IUpdatedRecords> {
    return this.patch$<IUpdatedRecords>(`/api/auth/permission/${id}`, permission);
  }

  public permissionGroups(): Observable<IPermissionGroupDTO[]> {
    return this.get$<IPermissionGroupDTO[]>("/api/auth/permission-groups");
  }

  public createOrUpdatePermissionGroup(data: ISavePermissionGroupDTO): Observable<IUpdatedRecords> {
    return this.put$<IUpdatedRecords>("/api/auth/permission-group", data);
  }

  public getOperatorPermissions(operatorGgId: string): Observable<PermissionTinyDTO[]> {
    return this.get$<PermissionTinyDTO[]>(`/api/auth/operator/${operatorGgId}/permissions`);
  }

  public getOperatorsPermissions(operatorGgIds: Set<string>): Observable<Map<string, PermissionTinyDTO[]>> {
    return this.post$<IOperatorsPermissionsDTO>("/api/auth/operators/permissions", [...operatorGgIds]).pipe(
      map((permissions: IOperatorsPermissionsDTO) => new Map(Object.entries(permissions)))
    );
  }

  public updateOperatorPermissions(data: IUpdateOperatorsPermissionDTO): Observable<IUpdatedRecords> {
    return this.put$<IUpdatedRecords>("/api/auth/operators/permissions", MapToStringKeyedObject(data));
  }
}

export const PermissionService = new Service();
