import { Observable } from "rxjs";

import HTTPService from "./HTTPService";
import { IEventDTO } from "@/services/DTOs/EventDTO";

class Service extends HTTPService {
  public log(data: IEventDTO): Observable<{id: number}> {
    const url = "/api/master-chief/event";

    return this.post$<{id: number}>(url, data);
  }
};

export const EventService = new Service();
