import { Observable } from "rxjs";

import { ISaveSubscriptionDTO, ISubscriptionDTO, ISubscriptionSummaryDTO, IUnsubscribeDTO } from "./DTOs/SubscriptionsDTO";
import HTTPService from "./HTTPService";

class Service extends HTTPService {
  public subscribe(subscription: ISaveSubscriptionDTO): Observable<void> {
    return this.post$("/api/subscriptions", subscription);
  }

  public unsubscribe(unsubscribe: IUnsubscribeDTO): Observable<void> {
    return this.delete$(`/api/subscriptions`, undefined, unsubscribe);
  }

  public getSubscription(ggId: string): Observable<ISubscriptionDTO> {
    return this.get$<ISubscriptionDTO>(`/api/subscriptions/${ggId}`);
  }

  public update(subscription: ISaveSubscriptionDTO): Observable<void> {
    return this.put$("/api/subscriptions", subscription);
  }

  public getAllActiveSubscription(): Observable<ISubscriptionSummaryDTO[]> {
    return this.get$<ISubscriptionSummaryDTO[]>("/api/subscriptions");
  }
}

export const SubscriptionService = new Service();
