import Hashids from "hashids";

export class DiscoveryHash {
  private static hashids = new Hashids(
    "jS5qAlR0kHbAeqeuVowPBnyotKuTvvlGzIzkEtz79XQGB7BJHb",
    8,
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890"
  );

  static numberToHash(input: number): string {
    return this.hashids.encode(input);
  }

  static hashToNumber(input: string): string {
    return this.hashids.decode(input)[0]?.toString() || "";
  }
}
