import { addGlobalContexts, removeGlobalContexts, setUserId, trackStructEvent } from "@snowplow/browser-tracker";
import { CommonEventProperties, StructuredEvent } from "@snowplow/tracker-core";

import SnowplowSchemas from "@/assets/ts/SnowplowSchemas";
import { Person } from "@/services/DTOs/Person";
import { ISnowplowContext, ISnowplowSchema } from "@/services/DTOs/tracking/SnowplowContext";
import { UserSnowplowContext } from "@/services/DTOs/tracking/UserSnowplowContext";
import { isTester } from "@/utils/TesterUtils";

let currentUser: any = null;
let userContext: UserSnowplowContext | null = null;
let schemaSnowPlowList: any = null;

type SnowplowStructuredEvent = StructuredEvent & CommonEventProperties;

function parseSnowplowContext(context: ISnowplowContext): ISnowplowSchema {
  return context.parseSchema();
}

function removeGlobalContext(context: ISnowplowSchema): ISnowplowSchema {
  removeGlobalContexts([context]);
  return context;
}

function initTracker(app: Vue): void {
  updateUserContexts(app.$store.state.bioPerson);
  initGlobalContexts();
}

function initGlobalContexts(): void {
  schemaSnowPlowList = loadSchemas();

  const contexts: ISnowplowSchema[] = (userContext ? [userContext] as ISnowplowContext[] : [] as ISnowplowContext[])
    .filter(context => !!context)
    .map(parseSnowplowContext)
    .map(removeGlobalContext);

  addGlobalContexts(contexts);
}

function signIn(person: Person): void {
  const { isTorrex, subjectId, ggId, name, email, phone } = person;

  setUserId(`${subjectId}`);
  currentUser = { isTorrex, subjectId, ggId, name, email, phone };
  currentUser.isTestAccount = isTester(currentUser);
  userContext = new UserSnowplowContext(currentUser);
}

function updateUserContexts(user?: Person): void {
  if (!user && currentUser) {
    currentUser = null;
    userContext = null;
  } else if (user && !currentUser) {
    signIn(user);
  }
}

function loadSchemas() {
  return (SnowplowSchemas || {}) as { [key: string]: string };
}

function prepareEvent(payload: StructuredEvent, optionalContext?: ISnowplowContext[]): SnowplowStructuredEvent {
  if (!schemaSnowPlowList) {
    schemaSnowPlowList = loadSchemas();
  }

  const contexts: ISnowplowSchema[] = (optionalContext ?? [])
    .concat(userContext ?? [])
    .filter(context => !!context)
    .map(parseSnowplowContext);

  return {
    category: payload.category,
    action: payload.action,
    label: payload.label,
    property: payload.property,
    value: payload.value,
    context: contexts
  };
}

function track(payload: StructuredEvent, context?: ISnowplowContext[]): void {
  const eventPayload = prepareEvent(payload, context);

  if (eventPayload) {
    trackStructEvent(eventPayload);
  }
}

export const SnowplowService = {
  initTracker,
  track,
  updateUserContexts
};
