import axios, {
  AxiosError,
  AxiosHeaders,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  CancelTokenSource,
  InternalAxiosRequestConfig
} from "axios";
import { from, map, Observable, Subject } from "rxjs";

import { ForwardedService } from "./DTOs/Misc";
import { mapError } from "@/helpers/ObservableUtils";

export default abstract class HTTPService {
  private client: AxiosInstance;
  private maxRetry = 3;
  private retry = 1;
  private timeoutMilliSeconds = 2 * 60 * 1000; // 2 minutes
  private cancelTokens = new Map<string, CancelTokenSource>();

  constructor() {
    this.client = axios.create({
      headers: {
        Accept: "*/*"
      },
      timeout: this.timeoutMilliSeconds,
      timeoutErrorMessage: "The request took too long and has failed.",
      withCredentials: true
    });
    this.initResponseInterceptor();
    this.initRequestInterceptor();
  }

  protected instantiateCustomHeaders = (headers: { [key: string]: string }) => {
    this.client.defaults.headers = {
      ...this.client.defaults.headers,
      ...(new AxiosHeaders(headers))
    };

    return this;
  };

  protected get$<T>(url: string, queryParams?: object): Observable<T> {
    return from(this.client.get<T>(url, { params: queryParams }))
      .pipe(
        map(this.typeCastAxiosResponse),
        mapError
      );
  };

  protected post$<T>(url: string, body: object, queryParams?: object): Observable<T> {
    return from(this.client.post<T>(url, body, { params: queryParams }))
      .pipe(map(this.typeCastAxiosResponse), mapError);
  };

  protected put$<T>(url: string, body: object, queryParams?: object): Observable<T> {
    return from(this.client.put<T>(url, body, { params: queryParams }))
      .pipe(map(this.typeCastAxiosResponse), mapError);
  };

  protected patch$<T>(url: string, body: object, queryParams?: object): Observable<T> {
    return from(this.client.patch<T>(url, body, { params: queryParams }))
      .pipe(map(this.typeCastAxiosResponse), mapError);
  };

  protected delete$<T>(url: string, queryParams?: object, body?: object): Observable<T> {
    return from(this.client.delete(url, { data: body, params: queryParams }))
      .pipe(map(this.typeCastAxiosResponse), mapError);
  };

  protected forwardGet$<T>(
    service: ForwardedService,
    url: string,
    queryParams?: object
  ): Observable<T> {
    return this.get$<T>(this.getForwardedServiceURL(service, url), queryParams);
  };

  protected forwardPost$<T>(
    service: ForwardedService,
    url: string,
    body: object,
    queryParams?: object
  ): Observable<T> {
    return this.post$<T>(this.getForwardedServiceURL(service, url), body, queryParams);
  };

  protected forwardPut$<T>(
    service: ForwardedService,
    url: string,
    body: object,
    queryParams?: object
  ): Observable<T> {
    return this.put$<T>(this.getForwardedServiceURL(service, url), body, queryParams);
  }

  protected forwardPatch$<T>(
    service: ForwardedService,
    url: string,
    body: object,
    queryParams?: object
  ): Observable<T> {
    return this.patch$<T>(this.getForwardedServiceURL(service, url), body, queryParams);
  }

  protected forwardDelete$<T>(
    service: ForwardedService,
    url: string,
    queryParams?: object
  ): Observable<T> {
    return this.delete$<T>(this.getForwardedServiceURL(service, url), queryParams);
  }

  private getForwardedServiceURL(service: ForwardedService, url: string): string {
    return `/api/forward/${service}${url}`.replace("//", "/");
  }

  private initRequestInterceptor = (): void => {
    this.client.interceptors.request.use(this.handleRequest, this.handleError);
  };

  private initResponseInterceptor = (): void => {
    this.client.interceptors.response.use(
      this.handleResponse,
      this.handleError
    );
  };

  private handleRequest = (config: InternalAxiosRequestConfig) => config;

  private handleResponse = <T>({ data }: AxiosResponse<T>): T => {
    this.retry = 1;
    return data;
  };

  private typeCastAxiosResponse<T>(response: AxiosResponse<T>): T {
    return response as unknown as T;
  };

  private handleError = async(error: AxiosError): Promise<AxiosError> => {
    const statusCode = error.response?.status;

    if (statusCode === 401) {
      if (this.retry <= this.maxRetry) {
        this.retry++;
        const newToken = await window.$nuxt?.$fire?.auth?.currentUser?.getIdToken(true);
        const originalRequest = error.config;

        if (originalRequest) {
          if (newToken) {
            originalRequest.headers!["Cortana-Auth"] = newToken;
          }

          return this.client.request(originalRequest);
        } else {
          return Promise.reject(error);
        }
      } else {
        await window.$nuxt.$store.dispatch("logoutAction", "max-retry-exceeded");
      }
    }
    return Promise.reject(error);
  };

  protected cancellablePostStream(url: string, body: string): Observable<string | object> {
    if (this.cancelTokens.has(url)) {
      this.cancelTokens.get(url)?.cancel("api.cancelled.request");
      this.cancelTokens.delete(url);
    }

    this.cancelTokens.set(url, axios.CancelToken.source());

    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-ndjson"
      },
      data: body,
      url,
      cancelToken: this.cancelTokens.get(url)?.token
    } as AxiosRequestConfig;

    const subject = new Subject<string | object>();

    axios(config)
      .then((response: AxiosResponse) => {
        subject.next(response.data);
        this.cancelTokens.delete(url);
      })
      .catch((err) => {
        subject.error(err);
        if (err && err.message !== "api.cancelled.request") {
          this.cancelTokens.delete(url);
        }
      })
      .finally(() => {
        subject.complete();
        this.cancelTokens.delete(url);
      });

    return subject.asObservable();
  }
}
